.App {
  text-align: center;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: "Metropolis" !important;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum";
  font-style: normal !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #C99E67;
  font-weight: 600;
}

.linkBlk {
  color: #000;
  font-size: 14px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  Add here All Custom Css */

.login-form {
  margin: 0 auto !important;
  float: none !important;
  margin-bottom: 10px !important;
  width: 430px !important;
}

.a-remove-text-decoration {
  text-decoration: none !important;
  color: #000 !important;
}

.a-rm-color {
  text-decoration: none !important;
  color: #fff !important;
  /* font-size: 16px !important; */
}

.menu-style {
  height: 100vh;
}

.profile-menu {
  float: right;
  width: 80px;
  height: 36px !important;
  padding: 0 !important;
}

.profile-menu .ant-menu-submenu::after {
  border: 0 !important;
}

.profile-menu>img {
  background-color: red;
}

.profile-media {
  height: 10px !important;
  width: 10px !important;
}

.login-form>.ant-card-head {
  font-family: "Metropolis";
  color: black;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  padding-top: 13px;
}

.login-body {
  background-color: #c99e67 !important;
}

.loginBox {
  width: 430px;
}

.login-button {
  background: #c99e67 !important;
  border: none !important;
  font-family: "Metropolis";
  font-size: 20px !important;
  font-weight: bold !important;
  align-items: center;
  height: unset !important;
  padding: 15px !important;
  border-radius: 0% !important;
}

.adminLayout .logo-text {
  padding: 0 0px !important;
  color: white;
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}

.adminLayout .meridiem-logo {
  height: 32px;
  margin: 16px;
  color: #fff;
}

/* .menu-style-custome {
    background-image: linear-gradient(
      144deg,
      #fa3881,
      #5b316b 50%,
      #391464
    ) !important;
    color: #fff !important;
  } */

/* .menu-style-custome:hover {
    background-image: linear-gradient(
      144deg,
      #391464,
      #5b316b 50%,
      #fa3881
    ) !important;
    color: #fff !important;
  } */

.scroll-off-fix {
  height: 600px;
  overflow-y: scroll;
  position: fixed;
  width: 200px !important;
}

.profile-menu {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: transparent !important;
  margin-top: 7px;
}

.profile-menu .ant-menu-submenu {
  opacity: 1 !important;
  height: 50px !important;
  width: 50px !important;
  order: 1;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.profile-img-icon {

  margin: -5px 10px 0 0;
  border-radius: 50%;
}

.ant-menu-submenu .ant-image-img {
  border-radius: 50%;
}

.ant-layout-header.header {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 98;
}

aside.ant-layout-sider {
  position: fixed;
  left: 0;
  z-index: 99;
  min-width: 280px !important;
}

.site-layout-background {
  padding-left: 30px;
  padding-top: 90px;
  padding-right: 20px;
}

.my-header-color {
  background: #ffff !important;
  color: #ffff !important;
}

/* .my-sider-color,
  .ant-layout-sider-trigger {
    background: #ff7540 !important;
    color: #ffff !important;
  } */

.my-sidermenu-color,
.ant-layout-sider-trigger {
  background: #c99e67 !important;
  color: #fff !important;
}

.my-sider-color>.ant-menu-item a,
.my-sider-color>.ant-menu-item-selected {
  color: #fff !important;
}

.ant-menu-item-selected {
  background: #000 !important;
  color: #fff !important;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff !important;
  background: #000 !important;
}

.my-theme-color-button>button,
.my-theme-color-button-fa {
  color: #fff !important;
  background: linear-gradient(90deg, #ff7540 0%, #a1c4de 100%) !important;
  margin: 2px;
  border: none;
}

.my-submit-button {
  color: #fff !important;
  background: #c99e67 !important;
  border: solid 1px #c99e67 !important;
  font-family: "Metropolis";
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 8px 15px !important;
  border-radius: 0% !important;
}

.my-submit-button:hover {
  color: #fff !important;
  background: rgba(201, 158, 103, 0.8) !important;
  border: solid 1px #c99e67 !important;
}

.my-reset-button {
  color: #c99e67 !important;
  background: #fff !important;
  border: solid 1px #c99e67 !important;
  font-family: "Metropolis";
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 8px 15px !important;
  border-radius: 0% !important;

}

.my-reset-button:hover {
  color: #c99e67 !important;
  background: #fff !important;
  border: solid 1px #c99e67 !important;
}

.profile-menu>.ant-menu {
  background: none;
}

.profile-menu>.ant-menu-horizontal {
  border: none;
}

/* Switch 1 Specific Styles Start */

.switchCustom input[type="checkbox"].switch_1 {
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.8em;
  height: 1.5em;
  background: #d60000;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.switchCustom input[type="checkbox"].switch_1:checked {
  background: #2bc512;
}

.switchCustom input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.switchCustom input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

/* Switch 1 Specific Style End */

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.uploader label {
  clear: both;
  width: 100%;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 0;
  border: 1px solid #ced4da;
  transition: all 0.2s ease;
  user-select: none;
  height: 150px;
  display: flex;
  align-items: center;
}

#start {
  float: left;
  clear: both;
  width: 100%;
}

#start .hidden {
  display: none;
}

#response {
  float: left;
  clear: both;
  width: 100%;
}

#response .hidden {
  display: none;
}

#response #messages {
  margin-bottom: 0.5rem;
}

#file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

#file-image .hidden {
  display: none;
}

.uploader input[type="file"] {
  display: none !important;
}

/* login page start */
.forheight {
  height: 100vh;
  background-color: #c99e67;
}

.setpassword {
  font-family: "Metropolis";
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: end;
}

.ant-layout {
  background-color: #ffff !important;
}

.card-header {
  background-color: #fff !important;
}

.PaginationLink .pagination {
  float: right;
  padding: 10px;
}

.PaginationLink .pagination .page-link {
  color: #000 !important;
  border-radius: 0% !important;
}

.PaginationLink .pagination .page-item .page-link:hover,
.PaginationLink .pagination .page-item.active .page-link {
  background-color: #000 !important;
  color: #fff !important;
  border: 0;
}

.selectboder {
  border-radius: 0% !important;
}

.tabCustom .nav-tabs .nav-link {
  background-color: #c99e67;
  color: #fff;
}

.tabCustom .nav-tabs .nav-link:hover {
  background-color: #000;
  color: #fff;
}

.tabCustom .nav-tabs .nav-link.active {
  background-color: #000;
  color: #fff;
}

.proposalBtnAbs {
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
}

.addclubbutton {
  font-size: 12px !important;
  font-weight: 700 !important;
  font-style: bold !important;
  border-radius: 0% !important;
}

.ant-table-thead>tr>th {
  font-family: "Metropolis";
}

label {
  font-size: 14px !important;
}

.fontred {
  color: red;
}

hr.lineTheme {
  border-top: solid 2px #ffedd6;
  opacity: 1;
}

.customHEAD .ant-card-head-title {
  margin: 20px 0;
}

.fontsizeset {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.password-text {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #424242;
}

.fontsize {
  font-size: 16px !important;
}

.ant-menu-light .headerMenuCustom {
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.ant-menu-light .headerMenuCustom:hover {
  background-color: #c99e67 !important;
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
}

.ant-menu-title-content {
  display: flex !important;
  align-items: center;
}

.ant-menu-title-content span {
  line-height: unset !important;
  height: unset !important;
  margin-right: 10px !important;
}

.Badgeclass {
  background-color: #E0FDED !important;
  color: #047438 !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.Badgeclassinactive {
  background-color: #FFDBDB !important;
  color: #740404 !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.Badgeclassactive {
  background-color: #e3ffdb !important;
  color: #31be1e !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.BadgeclassMarketing {
  background-color: #DBF8FF !important;
  color: #044C74 !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.BadgeclassFundRaise {
  background-color: #E0FFDB !important;
  color: #047409 !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.BadgeclassVoting {
  background-color: #DCDBFF !important;
  color: #03006C !important;
  border-radius: 0% !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  min-width: 110px;
}

.viewbutton {
  background-color: #9b9b9b !important;
  width: 30px !important;
  height: 30px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0% !important;
  border: 0;
  display: flex;
}

.editbutton {
  background-color: #dea71b !important;
  width: 30px !important;
  height: 30px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0% !important;
  border: 0;
  display: flex;
}

.deletebutton {
  background-color: #d60000 !important;
  width: 30px !important;
  height: 30px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0% !important;
  border: 0;
  display: flex;
}

.checkbutton {
  background-color: #2bc512 !important;
  width: 30px !important;
  height: 30px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0% !important;
  border: 0;
  display: flex;
}

.checkboxlable {
  border-radius: 0% !important;
  min-height: unset !important;
}

.checkboxlable .form-check-input:checked {
  background-color: #000;
  border-color: #fff;
}

.table-responsive table th,
.table-responsive table td {
  font-size: 14px !important;
}

.customdescription.form-control {
  line-height: 1.4;
}

/* For radio button */
.customRadioBtn .radioInput [type="radio"] {
  display: none;
}

.customRadioBtn .radioInput label {
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.customRadioBtn .radioInput label:before {
  content: "";
  position: absolute;
  left: -24px;
  top: -1px;
  width: 28px;
  height: 28px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

.customRadioBtn .radioInput label:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #000000;
  position: absolute;
  top: 4px;
  left: -19px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.customRadioBtn .radioInput [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.customRadioBtn .radioInput [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* End */

.form-control {
  font-weight: 600;
  font-size: 14px !important;
}

.hr {
  margin: 0px;
  width: 100%;
}

.modeltable th {
  background-color: #f4f4f4;
  border-width: 1px !important;
  padding: 10px;
}

.modeltable tr {
  border-width: 1px !important;
}

.modeltable td {
  padding: 10px;
}

.investmentroundtable th {
  padding: 10px !important;
  width: 300px !important;
}

.investmentroundtable td {
  padding: 10px !important;
}

.usdtAbs {
  position: absolute;
  right: 5px;
  top: 8px;
}

.ant-menu-inline>.ant-menu-item {
  line-height: unset !important;
}

.login-body .form-control {
  border-color: #dddddd;
  padding: .7rem .75rem;
  border-radius: 0;
}

.linkfontcolor {
  color: #C99E67 !important;
}

.viewset {
  position: absolute;
  right: 15px;
  top: 8px;
  text-decoration: underline;
  font-weight: 600 !important;
}

.ant-layout-sider-children .ant-menu-item {
  padding-left: 16px !important;
}

.ant-menu-inline .ant-menu-item:after {
  border: 0 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0 !important;
}

.tabCustom .nav-item {
  margin-right: 1px;
}

.tabCustom .nav-item .nav-link {
  font-size: 16px;
  font-weight: 500;
}

.inputCustom {
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
}

.inputCustom::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.table-responsive table th {
  background-color: #F4F4F4;
}

.form-select {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.actionDropdown {
  border: solid 1px #E9E9E9;
  border-radius: 0 !important;
}

.actionDropdown .btn,
.actionDropdown .dropdown-item {
  font-size: 14px !important;
}

.actionDropdown .dropdown-menu {
  border-radius: 0;
  padding: 0;
  border: solid 1px #E9E9E9;
  top: 10px;
}

.inputOuter_ {
  position: relative;
}

.inputOuter_ .defLabel_ {
  position: absolute;
  top: -11px;
  left: 25px;
  background: #fff;
  padding: 0 8px;
  font-family: "Metropolis";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inputOuter_ .defInput_ {
  border: unset;
  outline: transparent;
  height: 56px;
  border: 1px solid #000;
  border-radius: 0;
  padding-left: 33px;
  background-color: #fff !important;
}

.inputOuter_ .defInput_:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 121, 68, 0.118) !important;
  background-color: #fff !important;
}

.inputOuter_ .defInput_::-moz-placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff !important;
}

.inputOuter_ .defInput_:-ms-input-placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff !important;
}

.inputOuter_ .defInput_::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff !important;
}

.loginTitle {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 40px;
}

.login-form {
  border-radius: 0% !important;
}

.customDatePicker {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.box-title {
  font-size: 20px;
  font-weight: 700;
}

.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.20);
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: 0.5rem;
  position: relative;
  width: 100%;
  color: #000000;

  // .info-box-icon{
  //     border-radius:.25rem;
  //     -ms-flex-align:center;
  //     align-items:center;
  //     display:-ms-flexbox;
  //     display:flex;
  //     font-size:1.875rem;
  //     -ms-flex-pack:center;
  //     justify-content:center;
  //     text-align:center;
  //     width:62px;
  //     height: 62px
  // }
  .info-box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.8;
    flex: 1;
    padding: 0 10px;

    // overflow: hidden;
    .info-box-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
    }

    .info-box-number {
      display: block;
      margin-top: 0.25rem;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.info-box .info-box-icon>img {
  max-width: 100%;
  width: 30px;
}

.ant-layout-sider-children {
  position: fixed;
}




.customCardBox {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.20);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px 25px;

  ._icon {
    margin-top: 10px;
    svg {
      font-size: 50px;
    }
  }
  .cardContentArea {
    display: flex;
    flex-direction: column;

    ._title {
      color: #8898aa;
      font-size: 16px;
      font-weight: 600;
    }

    ._number {
      color: #000;
      font-size: 35px;
      font-weight: 700;
      // margin-bottom: 15px;
    }

    ._percent, ._duration {
      color: #8898aa;
      font-size: 18px;
      font-weight: 500;
    }
  }
}