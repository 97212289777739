

$primary : #E67161;
$black : #000;
$white : #fff;
$green : #369709;
$gold : #D8BE38;
$goldDark : #BDA110;
$gray : #A3A3A3;
$lightgray:#F2F2F2;
$darkText : #747474;
$platinum : #DDDDDD;
$platinumDark : #9A9A9A;
$blackGray : #414141;
$red:#FF0000;
$GrayNew : #ccc;
$goldenNew : #CA9E67;
$GreenNew : #5AA94A;
$Cyan :#9BCCC6
