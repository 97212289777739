.payment-customsuccess{
  background-color: #c99e67;
  border-radius: 15px 15px 0 0;
}
.payment-customspace{
  padding: 15px 350px;
}
.payment-customspace svg {
    fill: #fff;
}
.payment-topHalf{
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
        margin-top: 40px;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        word-spacing: 10px;
        
    }
    h3 {
        line-height: 30px;
        font-size: 30px;
        font-weight: 500;
        span{
            font-size: 20px;
            font-size: 20px;
            font-weight: 400;
        }
    }
    p{
       
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
    }
    .payment-p{
        line-height: 25px;
        font-size: 18px;
        font-weight: 400;
        padding: 0 20px;
    }

}
.payment-custombox{
    border-radius: 20px;
    border: 1px solid #c99e67;
}
.payment-button {
    margin-top:10px;
    margin-bottom: 30px;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 15px;            
    padding: 8px 45px;            
    background-color: #c99e67 ;
    font-size: 24px;
    font-weight: 500px;
}
.payment-button:hover {
    background-color: #ecc694;
}
.payment-bgheight{
    height: 80vh;
}        


