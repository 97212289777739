/* Opportunity Detail Page */
.section-left {
	/* width: 374.88px; */
}
.bg-dark-v1 {
	background-color: #000000 !important;
}
.opportunity-details .opportunity-title {
	font-size: 20px;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	font-family: "Metropolis";
	color: #ffffff;
}
.opportunity-details .detail {
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	color: #ffffff;
}
.opportunity-details .download-btn {
	background-color: #e2614e;
	color: #ffff;
	text-transform: uppercase;
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	/* white-space: nowrap; */
	text-align: center;
	padding: 12px 10px;
	display: block;
}

.opportunity-details .download-btn:hover {
	background-color: #e67161cc;
	color: #fff;
}
.investment-section {
	
	min-height: 168.83px;
}
.investment-section-v1 {
	
	min-height: 158px;
}
.investment-section .investment-title,
.investment-section-v1 .investment-title {
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: #000000 !important;
}
.investment-subtitle {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	color: #000000;
}
.opportunity-details .progressLine {
	height: 7px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.opportunity-details .cryptoIconBg {
	background-color: #e67161;
	height: 90px;
	width: 90px;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.opportunity-details .cryptoIconBg .icon {
	height: 50px;
	width: 50px;
}
.opportunity-details .opportunityImg-3 {
	background-size: contain;
	background-image: url("../../../assets/images/opportunity-3.png");
	background-position: center right;
	background-repeat: no-repeat;
	
}
.opportunity-details .opportunityImg-4 {
	background-size: contain;
	background-image: url("../../../assets/images/creamTriangle.png");
	/* background-position: center right; */
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto;
	
}
.opportunity-details .titleH3 {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
}
.customyearmargin{
	margin-bottom: 100px;
}
.opportunity-details .statistics {
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: #000000 !important;
	margin-bottom: 30px;
}
.opportunity-details .bigText {
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 700;
	font-size: 130px;
	line-height: 130px;
	color: #000000;
}
.opportunity-details .infoText {
	font-family: "Metropolis";
	font-style: normal;
	font-weight: 700;
	font-size: 80px;
	line-height: 60px;
	color: #e3614f;
}
.opportunity-details .opportunityImg-1 {
    background-image: url("../../../assets/images/opportunity-1.png");
    background-size: contain;
	min-height: 230px;
  }
  
  .opportunity-details .opportunityImg-2 {
    background-image: url("../../../assets/images/opportunity-2.png");
    background-size: contain;
  }
   
  /* === createOpportunity === */
  .custinput{
	border: unset;
    outline: transparent;
    height: 55px;
    font-size: 14px !important;
	font-weight: 400 !important;
    padding-left: 23px;
    border: 1px solid #000;
    border-radius: 0;
    background-color: #fff !important;
  }
  .uploader label {
	border: 1px solid black !important;
  }
  .form-check-input{
	border: 1px solid black !important;
  }
  .customLable{
	font-size: 16px !important;
  }
.customtextarea{
	height: 150px !important;
} 
.inputOuter_{
	margin-bottom: 40px !important;
}
.customminheight{
	min-height: 500px;
}
  
