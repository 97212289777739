@import "variable";

.bgGray {
  background-color: #e9e9e9;
}

.bgCream {
  background-color: #f7f0e8;
}

.bgLightCream {
  background-color: #fcfaf7;
}

.textLightGray {
  color: #ffffff;
}

.height-120 {
  height: 120px;
}

.height-150 {
  height: 150px;
}

.height-200 {
  height: 200px;
}

.height-300 {
  height: 300px;
}

.opportunitiesPage {
  .opportunityImg-1 {
    background-image: url(../images/opportunity-1.png);
    background-size: contain;
  }

  .opportunityImg-2 {
    background-image: url(../images/opportunity-2.png);
    background-size: contain;
  }

  .opportunityImg-3 {
    background-size: contain;
    background-image: url(../images/opportunity-3.png);
    background-position: center right;
    background-repeat: no-repeat;
  }

  .title {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 20px;
  }

  .detail {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }

  .siteThemeBtn {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    color: #ffffff;
  }

  .titleH3 {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
  }

  .cryptoIconBg {
    background-color: $primary;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 50px;
      width: 50px;
    }
  }

  .progressLine {
    height: 7px;
    display: flex;

    span {
      height: 100%;
    }
  }

  .infoText {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 60px;
    color: #e3614f;
  }

  .bigText {
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 700;
    font-size: 130px;
    line-height: 130px;
    color: #000000;
  }
}

.custForm {
  .custLabel {
    position: absolute;
    top: -11px;
    font-size: 16px;
    left: 16px;
    background: #fff;
    padding: 0 8px;
    // font-family: "Poppins";
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .custInput {
    border: unset;
    outline: transparent;
    height: 56px;
    font-size: 14px;
    padding-left: 23px;
    border: 1px solid #000;
    border-radius: 0;
    background-color: #fff !important;

    &.fileInput {
      width: 100%;
      height: 100px;
      padding-top: 17px;
      padding-left: 0;
      cursor: pointer;
    }
  }

  .fileUploadIcon2 {
    height: 35px;
  }
}

// changes
.customposition {
  position: absolute;
  right: 7px;
  bottom: 5px;
  background-color: $white;
}

.breadcrumb-item a {
  color: $black !important;
  z-index: 9;
}

.breadcrumb-item.active {
  color: $black !important;
  font-weight: 500;
}

.btnBuySM {
  padding: 8px !important;
}

.btnvoteSM {
  padding: 4px 8px !important;
}

.form-select {
  border-radius: 0%;
  border: 1px solid $black;
  font-size: 14px;
  font-weight: 400px !important;
}

.modalComman {
  .modal-header {
    font-weight: 700;
    font-size: 20px;
  }
  .modal-content {
    border-radius: 0;
  }

  h3 {
    font-size: 30px;
    font-weight: 600;
  }

  h5 {
    font-weight: 400;

    span {
      font-weight: 600;
      color: $red;
    }
  }

  span {
    font-weight: 600;
    color: $black;
  }

  h4 {
    font-weight: 700px;
    font-size: 20px;
  }
}

.customvotebtn {
  display: inline-grid !important;
  text-align: center;
}

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  // max-width: 600px;
}

.uploader label {
  clear: both;
  width: 100%;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 0;
  border: 1px solid $black;
  transition: all 0.2s ease;
  user-select: none;
  height: 140px;
  display: flex;
  align-items: center;
}

#start {
  float: left;
  clear: both;
  width: 100%;
}

#start .hidden {
  display: none;
}

#response {
  float: left;
  clear: both;
  width: 100%;
}

#response .hidden {
  display: none;
}

#response #messages {
  margin-bottom: 0.5rem;
}

#file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

#file-image .hidden {
  display: none;
}

.uploader input[type="file"] {
  display: none !important;
}

.customtextarea {
  border: 1px solid #000;
  font-size: 14px;
  height: 140px !important;
}

.footerlink {
  color: $goldenNew;
  font-weight: 700;
  font-size: 14px;
}

.progress {
  --bs-progress-bg: #fff !important;
  height: 30px !important;
  border-radius: 0%;
  font-size: 12px;

  .orangebd {
    background-color: #e3614f !important;
    padding: 5px;
  }

  .bluebd {
    background-color: #44a1bb !important;
    padding: 5px;
  }

  .lightblack {
    background-color: #939393;
    padding: 5px;
  }

  .lightgreen {
    background-color: #9bccc6;
    padding: 5px;
  }

  .lightorange {
    background-color: #ffa17b;
    padding: 5px;
  }
}

.CircularProgressbar {
  width: 80% !important;
}

.livecircle {
  border: $black;
  background-color: $green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.tabLabourPull {
  .nav-tabs .nav-link.active {
    background-color: $black !important;
    color: $white !important;
    font-size: 20px;
    font-weight: 500;
  }

  .nav-tabs .nav-link {
    background-color: $lightgray !important;
    color: $black !important;
    font-size: 20px;
    font-weight: 500;
  }

  .tab-content {
    border: 1px solid rgb(0, 0, 0, 0.2) !important;
  }
}

.customlondon {
  .bg-secondary {
    background-color: $lightgray !important;
  }

  .badge {
    color: $black !important;
    font-size: 14px !important;
    border: 1px solid black;
  }
}

.customjob {
  .bg-secondary {
    background-color: $white !important;
  }

  .badge {
    color: $black !important;
    font-size: 14px !important;
    border: 1px solid black;
  }
}

.customprofile {
  background-color: $lightgray;
}

.Viewmorecircle {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: $goldenNew;
  display: inline-block;
}

.CenterLogo {
  display: block;
  margin-left: 25px;
  margin-top: 20px;
  width: 50%;
}

.customrectange {
  // width: 270px;
  // height: 100px;
  background-color: $goldenNew;
  display: inline-block;
  padding: 15px;

  .custombook {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 1px solid $black;
  }
}

.largeblackbox {
  width: 18px;
  height: 8px;
  background-color: $black;
  display: inline-block;
}

.largeCyanbox {
  width: 18px;
  height: 8px;
  background-color: $Cyan;
  display: inline-block;
}

.smallblackbox {
  width: 8px;
  height: 8px;
  background-color: $black;
  display: inline-block;
}

.smallCyanbox {
  width: 8px;
  height: 8px;
  background-color: $Cyan;
  display: inline-block;
}

// === Div Table ===

.divTable {
  // display: table;
  width: 100%;

  min-width: 100%;
  max-width: 600px;
  overflow-x: scroll;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  display: table-header-group;
}

.divTableCell,
.divTableHead {
  border: 0.5px solid #939393;
  display: table-cell;
  padding: 3px 10px;
  border-bottom: 1px solid $black;
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;

  max-width: 600px;
  overflow-x: scroll;
}

// .divTableBody .divTableRow .divTableCell:first-child {
//     width: 25%;
// }
.divTableBody .divTableRow:last-child .divTableCell {
  height: 300px;
}

.Mediumblackbox {
  background-color: $black;
  width: 28px;
  height: 12px;
}

.Mediumcyanbox {
  background-color: $Cyan;
  width: 28px;
  height: 12px;
}

.Mediumsmblackbox {
  background-color: $black;
  width: 12px;
  height: 12px;
}

.Mediumsmcyanbox {
  background-color: $Cyan;
  width: 12px;
  height: 12px;
}

.investmentNumMain {
  display: flex;
  position: relative;
  justify-content: center;

  .investmentNum {
    width: 40px;
    height: 40px;
    background-color: $black;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    z-index: 9;

    &.active {
      -webkit-box-shadow: 0px 0px 0px 13px $goldenNew;
      -moz-box-shadow: 0px 0px 0px 13px $goldenNew;
      box-shadow: 0px 0px 0px 13px $goldenNew;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 19px;
    left: 0;
    border-top: solid 2px #000;
    width: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 19px;
    right: 0;
    border-top: solid 2px #000;
    width: 50%;
  }
}

.investmenttopline {
  display: flex;
  position: relative;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    bottom: 19px;
    left: 0;
    border-top: solid 2px #000;
    width: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 19px;
    right: 0;
    border-top: solid 2px #000;
    width: 50%;
  }
}

.customtablewidth {
  min-width: 1500px;
}

@media (max-width: 1299px) {
  .investmentNumMain {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .customrectange {
      margin-right: 40px !important;
    }
  }

}

@media (max-width: 767px) {
  .opportunitiesPage {
    .detail {
      font-size: 16px;
    }
  }
}

@media (max-width: 767px) {
  .btn_Orange {
    .lm {
      font-size: 20px;
    }
  }

  .btn_White {
    .lm {
      font-size: 20px;
    }
  }
}