// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import 'variable';
@import 'mixin';

body {
    font-family: 'Metropolis';
    font-style: normal;
    background-color: #000000 !important;
    letter-spacing: 0;
}
a{text-decoration: none;}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    // -webkit-appearance:none;
    box-shadow: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.page_main h1 {
    font-family: 'Metropolis';
    @include font(22px, $white, 700);
}
.font14Blk {
    @include font(14px, $white, 400);
}
.navbarCustom {
    background-color: transparent !important;
    .navbar-nav {
        align-items: center;
        .nav-link {
            @include font(16px, $white, 400);
            padding-right: 0.8rem;
            padding-left: 0.8rem;
            &:hover {
                color: $primary;
            }
        }
    }
    .dropdown-menu {
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border: 0;
        .dropdown-item {
            @include font(14px, $black, 500);
            &:hover {
                background-color: transparent;
                color: $primary;
            }   
        }
        .dropdown-item.active {
            background: none;
        }
    }
    .profileImg {
        display: flex;
        align-items: center;
        img {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            border: solid 2px #E9E9E9;
        }
        .avatarTitle {
            margin-left: 10px;
            line-height: 16px;
            span {
                font-size: 12px;
            }
        }
    }
    #avatarDropdown.dropdown-toggle {
        display: flex;
        align-items: center;
    }
}
.btnBlack {
    background-color: $black;
    border-radius: 50px;
    padding: 6px 15px !important;
    @include font(16px, $white, 400);
    height: min-content;
    border: 0;
    &:hover, &.active {
        background-color:rgba($black, .8);
        color: $white !important;
    }
}
.btnWhite {
    background-color: $black;
    border-radius: 50px;
    padding: 6px 15px !important;
    @include font(16px, $white, 400);
    height: min-content;
    border: solid 1px $white;
    &:hover, &.active {
        background-color:$white;
        color: $black !important;
    }
}
.notificationNumber {
    background-color: $primary;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font(12px, $white, 500);
    border-radius: 100%;
    position: absolute;
    // right: 0;
    // top: 0;
    right: 14px;
    top: 6px;
}
.notificationItems {
    a {
        border: solid 1px $white;
        border-radius: 100%;
        padding: 0 !important;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a::after {display: none !important;}
    .dropdown-menu.show {
        width: 300px;
        border-radius: 10px;
        right: -15px;
        left: unset;
        padding: 0;
        overflow: hidden;
        .title {
            padding: 7px 15px;
            font-size: 12px;
            color: #000000;
            font-weight: 600;
        }
        .dropdown-item {
            border-bottom: 1px solid rgba($gray, .1);
            background-color: #f3f9fd97;
            font-size: 14px;
            color: #000000db;
            font-weight: 400;
            &:last-child {border-bottom: none;}
            padding: 10px 15px;
        }
    }
}
.my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
// Member page start
.membershipPage {
    .title {
        h4 {
            @include font(20px, $white, 500);
        }
        h2 {
            @include font(30px, $white, 700);
        }
    }
    .details {
        align-items: stretch;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .bodrBottom {
            border-bottom: solid 1px rgba($gray, .27);
        }
        .pricePart {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .detailRow {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                @include font(16px, $black, 400);
                line-height: 18px;
                margin: 0;
                span {
                    font-weight: 600;
                }
            }
        }
        .footer {
            margin-top: 50px;
            a {
                @include font(16px, $black, 500);
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .clickHereBtns .btn_Black {
        width: fit-content;
        display: flex;
        align-items: center;
        height: fit-content;
        padding: 15px 25px;
    }
}
.greenMembership {
    background-color: $white;
    border: solid 4px #369709;
    border-radius: 25px;
    max-width: 360px;
    margin: 0 auto;
    align-items: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        background: radial-gradient(113.46% 113.46% at 50.14% -41.15%, #7BE649 39.7%, #359608 100%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px 20px 0px 0px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h3 {
        @include font(26px, $green, 700);
        margin: 0;
        span {
            @include font(14px, $darkText, 400);
            display: block;
        }
    }
    .btnGreen {
        background-color: $green;
        border-radius: 50px;
        padding: 15px !important;
        @include font(16px, $white, 500);
        height: min-content;
        width: 100%;
        &:hover {
            background-color:rgba($green, .9);
            color: $white;
        }
    }
}
.GoldMembership {
    background-color: $white;
    border: solid 4px $gold;
    border-radius: 25px;
    max-width: 360px;
    margin: 0 auto;
    align-items: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        background: radial-gradient(113.46% 113.46% at 50.14% -41.15%, #F7DF66 39.7%, #BCA00F 100%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px 20px 0px 0px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h3 {
        @include font(26px, $goldDark, 700);
        margin: 0;
        span {
            @include font(14px, $darkText, 400);
            display: block;
        }
    }
    .btnGold {
        background-color: $goldDark;
        border-radius: 50px;
        padding: 15px !important;
        @include font(16px, $white, 500);
        height: min-content;
        width: 100%;
        &:hover {
            background-color:rgba($goldDark, .9);
            color: $white;
        }
    }
}
.platinumMembership {
    background-color: $white;
    border: solid 4px $platinum;
    border-radius: 25px;
    max-width: 360px;
    margin: 0 auto;
    align-items: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        background: radial-gradient(113.46% 113.46% at 50.14% -41.15%, #F0F0F0 39.7%, #999999 100%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px 20px 0px 0px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h3 {
        @include font(26px, $platinumDark, 700);
        margin: 0;
        span {
            @include font(14px, $darkText, 400);
            display: block;
        }
    }
    .btnGold {
        background-color: $platinumDark;
        border-radius: 50px;
        padding: 15px !important;
        @include font(16px, $white, 500);
        height: min-content;
        width: 100%;
        &:hover {
            background-color:rgba($platinumDark, .9);
            color: $white;
        }
    }
}
.blackMembership {
    background-color: $white;
    border: solid 4px $blackGray;
    border-radius: 25px;
    max-width: 360px;
    margin: 0 auto;
    align-items: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        background: radial-gradient(113.46% 113.46% at 50.14% -41.15%, #F0F0F0 39.7%, #000000 100%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px 20px 0px 0px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h3 {
        @include font(26px, $black, 700);
        margin: 0;
        span {
            @include font(14px, $darkText, 400);
            display: block;
        }
    }
    .btnGold {
        background-color: $black;
        border-radius: 50px;
        padding: 15px !important;
        @include font(16px, $white, 500);
        height: min-content;
        width: 100%;
        &:hover {
            background-color:rgba($black, .9);
            color: $white;
        }
    }
}
// Member page end

// Footer start
footer {
    background-color: $black;
    padding: 30px 0;
    .footerLogo {
        width: 100%;
    }
    h2 {
        @include font(30px, $white, 400);
    }
    p {
        @include font(14px, #929292, 400);
    }
    .subscribe {
        background: $white;
        padding: 0 0 0 20px;
        width: 100%;
        border: 1px solid #A3A3A3;
        border-radius: 50px !important;
        display: flex;
        input {
            background: $white;
            border: 0;
            @include font(14px, #929292, 400);
            padding: 5px;
        }
        .btnSubscribe {
            background-color: $black;
            border-radius: 50px !important;
            padding: 15px 30px;
            @include font(16px, $white, 400);
            height: min-content;
            border: solid 1px $white;
            &:hover, &.active {
                background-color:$primary;
                color: $white !important;
            }
        }
    }
    .footerNav {
        padding: 0;
        margin: 0;
        li {
            a {
                @include font(14px, $white, 700);
                text-decoration: none;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .footerSocial {
        margin-bottom: 30px;
        a {
            margin-right: 20px;
            &:last-child {
                margin: 0;
            }
        }
    }
    .address {
        @include font(16px, $white, 700);
    }
    .fontWhite14 {
        @include font(14px, $white, 400);
    }
    .linkWhite14 {
        @include font(14px, $white, 400);
        text-decoration: none;
        &:hover {
            color: $primary;
        }
    }
}
// Footer end
.investmentRoundStep {
    display: flex;
    .partLeft {
        h3 {
            @include font(18px, $black, 600);
        }
        .title {
            border: solid 1px $black;
            padding: 10px;
            @include font(14px, $black, 200);
            font-family: 'Metropolis Extra';
        }
    }
    .partMiddle {
        .progress-container{
            display: flex;
            position: relative;
           justify-content: space-between;
           z-index: 1;
           margin-top: 35px;
         }
         .progress{
           background-color:$black;
           position:absolute;
           top: 50%;
           left: 0;
           height: 4px;
           width: 0%;
           transform: translateY(-50%);
           z-index: -1;
           transition: 0.4s ease-in;
         }
         .progress-container::before{
           content: '';
           background-color: $black;
           position:absolute;
           top: 50%;
           left: 0;
           height: 1px;
           width: 100%;
           transform: translateY(-50%);
           z-index: -1;
           transition: 0.4s ease-in;
         }
         .circle{
           background-color:#fff;
           border-radius: 50%;
           height: 30px;
           width:30px;
           display: flex;
           justify-content: center;
           align-items: center;
           background-color: $black;
           @include font(16px, $white, 500);
           position: relative;
           &::before {
            content: "";
            min-width: fit-content;
            position: absolute;
            text-align: center;
            line-height: 16px;
            top: -35px;
            @include font(14px, $black, 500);
           }
           &.step1 {
            &::before {
                content: "Marketing";
            }
           }
           &.step2 {
            &::before {
                content: "Fund Raise";
            }
           }
           &.step3 {
            &::before {
                content: "Voting";
            }
           }
           &.step4 {
            &::before {
                content: "Results";
            }
           }
         }
         .circle.active{
            background-color: $primary;
            @include font(16px, $black, 500);
         }
    }
}
.statusSuccess {
    background-color: #E0FDED;
    @include font(14px, #047438, 500);
    padding: 10px 20px;
    border-radius: 0;
}

.statusReject {
    background-color: #FFDBDB;
    @include font(14px, #740404, 500);
    padding: 10px 20px;
    border-radius: 0;
    width: 100px ;
}
.font14ExLight {
    @include font(14px, $black, 200);
}
.font16BlkBold {
    @include font(16px, $black, 600);
}
.tableSM {
    table {
        thead {
            th {
                @include font(13px, $black, 400);
                white-space: nowrap;
            }
        }
        tbody {
            td {
                @include font(13px, $black, 600);
                vertical-align: middle;
                span {
                    font-weight: 400;
                }
            }
        }
    }
    .table-striped>tbody>tr:nth-of-type(odd)>* {
        background-color: rgba(242, 242, 242, 0.05);
    }
    .table-striped>tbody>tr:nth-of-type(even)>* {
        background-color: #E5E5E5;
    }
}
.viewMore {
    @include font(14px, $white, 600);
    line-height: 14px;
    letter-spacing: 0.05em;
    padding: 5px 10px;
    border: solid 1px $black;
    background-color: $black;
    &:hover {
        @include font(14px, $white, 600);
        background-color: rgba($black, .8);
    }
}
.catgSymbol {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #E2614E;
    display: flex;
    align-items: center;
    justify-content: center;
}
.propertySymbol {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #44A1BB;
    display: flex;
    align-items: center;
    justify-content: center;
}
.commoditySymbol {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #939393;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bondSymbol {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #9BCCC6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sharesSymbol {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #FFA17B;
    display: flex;
    align-items: center;
    justify-content: center;
}
.catgbigSymbol {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #E2614E;
    display: flex;
    align-items: center;
    justify-content: center;
}
.propertybigSymbol {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #44A1BB;
    display: flex;
    align-items: center;
    justify-content: center;
}
.commoditybigSymbol {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #939393;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bondbigSymbol {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #9BCCC6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sharesbigSymbol {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #FFA17B;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link12Blk {
    @include font(12px, $black, 600);
    &:hover {
        color: $black;
        text-decoration: underline;
    }
}
.font14LightBlk {
    font-family: 'Metropolis Extra';
    @include font(14px, $black, 200);
}
.font14BoldBlk {
    @include font(14px, $black, 600);
}
.btnBuy {
    background-color: $GreenNew;
    border-radius: 0;
    padding: 15px 8px !important;
    @include font(16px, $white, 500);
    height: min-content;
    width: 100%;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
        background-color:rgba($GreenNew, .9);
        color: $white;
    }
    svg {
        margin-right: 15px;
    }
}
.btnCancel {
    background-color: $black;
    border-radius: 0;
    padding: 15px 8px !important;
    @include font(16px, $white, 500);
    height: min-content;
    width: 100%;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
        background-color:rgba($black, .9);
        color: $white;
    }
    svg {
        margin-right: 15px;
    }
}
.btnSell {
    background-color: $black;
    border-radius: 0;
    padding: 15px 8px !important;
    @include font(16px, $white, 500);
    height: min-content;
    width: 100%;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
        background-color:rgba($black, .9);
        color: $white;
    }
    svg {
        margin-left: 15px;
    }
}
.investmentTable {
    .table {
        th{
            font-weight: 400;
            white-space: nowrap;
        }
        tbody {
            td {
                padding: 0;
                .hr {
                    border-bottom: solid 1px rgba($goldenNew, .3);
                    font-size: 12px;
                    min-height: 30px;
                }
                .lasttd{font-size: 12px;
                    min-height: 30px;}
            }
            tr:nth-child(odd) {background-color: #FCFAF7;}
        }
    }
}







@media (max-width: 1399px) {
    .mobileView {
        display: flex !important;
        align-items: center;
    }    
    .navbar-brand img {
        width: 200px;
    }
    .navbarMob {
        position: absolute;
        width: 100%;
        top: 66px;
        left: 0;
        background-color: $black;
        padding: 50px 10px;
        z-index: 9999;
    }
    .navbarCustom .navbar-nav .show>.nav-link {
        color: $primary;
    }
    .navbarCustom .navbar-nav .nav-link:focus,
    .navbarCustom .navbar-nav .nav-link.active {
        color: $white;
    }
    .navbarCustom .navbar-nav .nav-link {
        &:hover {
            color: $primary;
        }
    }
    #avatarDropdown.dropdown-toggle::after {
        display: none;
    }
    .navbarCustom .navbar-toggler {
        background-color: $black;
        border: 0;
        padding: 3px 5px;
        .navbar-toggler-icon {
            filter: invert(100%) saturate(10%);
        }
        svg {
            width: 25px;
        }
    }
    #avatarDropdown {
        padding-right: 0;
    }
    .profileImg {
        display: flex;
        align-items: center;
        img {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            border: solid 2px #E9E9E9;
        }
    }
    .avatarTitle {
        line-height: 16px;
        span {
            font-size: 12px;
        }
    }
    .mobileView a {
        margin-right: 12px;
        .notificationNumber {
            // right: -10px;
            // top: -12px;
            right: 3px;
            top: -10px;
        }
    }
}
@media (max-width: 767px) {
    .navbar-brand img {
        width: 150px;
    }
    .dropdown-menu {
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border: 0;
    }
    .footerCopyright {
        span {
            visibility: hidden;
            display: block;
            height: 0;
        }
    }
}
@media (min-width: 1400px) {
    .navbarCustom .navbar-nav .nav-link {
      font-size: calc(0.8125rem + ((1vw - 14px) * 0.5769));
      min-height: 0vw;
    }
    .navbar-brand img {
        width: calc(12.5rem + ((1vw - 14px) * 19.2308));
        min-height: 0vw;
    }
}
@media (min-width: 1920px) {
    .navbarCustom .navbar-nav .nav-link {
      font-size: 14px;
      font-weight: 700;
    }
    .navbar-brand img {
        width: 228px;
    }
}