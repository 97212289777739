.accordion-item-cutum>button {
  background: none !important;
}

.accordion-item-cutum {
  border: none !important;
}

.ant-upload-list-item-done {
  display: none;
}

.bg-none {
  background: none !important;
}

.search-investor-scroll {
  max-height: 330px;
  overflow-y: scroll;
}

.ant-picker-dropdown {
  /* left: 385px;
  top: 0px; */
  z-index: 9999 !important;
}

.inputOuter_ .defLabel_ {
  z-index: 9;
}

.profile-menu>.dropdown-menu {
  /* width: 140px;
  padding: 0px;
  margin: 0px; */
  left: auto;
  right: 0;
}

.profile-menu>.dropdown-menu .dropdown-item .dropdown-item {
  padding: 0;
}

.product-img {
  height: 300px;
}

.frontEndLayout .btn.disabled,
.frontEndLayout .btn:disabled,
fieldset:disabled {
  background-color: #FF7540 !important;
}

.cluNavLinkbItems {
  cursor: pointer;
}

/* custom css */
.iti--allow-dropdown input {
  width: 100%;
  /* height: 53px; */
  height: 38px;
  border: 0;
}

.iti__country-list {
  z-index: 99;
}

.iti {
  margin-left: -15px;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #C99E67; /* Light grey */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
}
.loaderSM {
  position: fixed;
  left: 50%;
  top: 50%;
}