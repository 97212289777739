// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import 'variable';
@import 'mixin';

body {
    font-family: 'Metropolis';
    // font-family: 'Poppins', sans-serif;
    // font-family: 'Metropolis';
    // background: rgb(255,117,64);
    // background: linear-gradient(90deg, rgba(255,117,64,1) 0%, rgba(161,196,222,1) 100%);
}
* {
    list-style-type: none !important;
    box-sizing: border-box !important;
}

// ======= defCss =======
.modal-backdrop.show {
    background: #000;
    opacity: 0.8;
}

.clrOrange_ {
    color: #FF7540;
    &:hover {color: #FF7540;}
}
.clrBlack_ {
    color: #424242;
}
.requireStar {
    color: #EA6453;
}

.height100vh_ {
    height: 100vh;
}

.flexColumn_ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.flexRow_ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.inputOuter_ {
    position: relative;
    .defLabel_ {
        position: absolute;
        top: -11px;
        left: 25px;
        background: #fff;
        padding: 0 8px;
        font-family: 'Metropolis';

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #000000;
    }
    .defInput_ {
        border: unset;
        // background: transparent;
        outline: transparent;
        height: 56px;
        // background: #FFFFFF;
        border: 1px solid $black;
        border-radius: 0;
        padding-left: 33px;
        background-color: $white !important;
        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(244, 121, 68, 0.118) !important;
            background-color: $white !important;
        }
        &::placeholder {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: rgba($black, .7);
            background-color: $white !important;
        }
    }
    .textarea {
        height: 125px !important;
        border: unset;
        outline: transparent;
        border: 1px solid $black;
        border-radius: 0;
        padding-left: 33px;
        background-color: $white !important;
        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(244, 121, 68, 0.118) !important;
            background-color: $white !important;
        }
        &::placeholder {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: rgba($black, .7);
            background-color: $white !important;
        }
    }
}

.btn_Black {

    text-align: center;
    color: $white;
    background: $black;
    border: none;
    height: fit-content;
    width: fit-content;
    border-radius: 0;
    white-space: nowrap;
    &:hover{
        background-color: rgba($black, .8);
    }
    &:active {
        background-color: rgba($black, .8);
    }
    &:focus {
        background-color: rgba($black, .8);
    }
}

.btn_Orange2 {

    text-align: center;
    color: $white;
    background: $primary;
    border: none;
    height: fit-content;
    width: fit-content;
    border-radius: 0;
    white-space: nowrap;
    &:hover{
        background-color: rgba($primary, .8);
    }
    &:active {
        background-color: rgba($primary, .8);
    }
    &:focus {
        background-color: rgba($primary, .8);
    }
}
// ===============

// ======== otp model =========
.otpModel {
    .modal-content {
        border-radius: 0;
        // padding: 15px;
        .modal-header {
            .modal-title {
                font-size: 20px;
                font-weight: bold;
            }
            .btn-close {
                // background-color: #EBEBEB;
                color: #000;
                opacity: 1;
            }
        }
        .modal-body {
            p {
                color: #3C3C3C;
            }
            .otpFromGroup {
                input {
                    background: #FFFFFF;
                    border: 1px solid $black;
                    width: 67px !important;
                    height: 70px !important;

                    font-weight: 300;
                    font-size: 45px;
                    line-height: 75px;
                    color: $black;
                    &:focus-visible {
                        outline: none;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .resendOtpDiv {
                span {
                    display: none;
                }
                button {
                    color: #FF7540;
                    border: none;
                    background: none;
                    font-weight: 500;
                    &:hover {color: #FF7540;}
                }
            } 
        }
        .modal-footer {
            .btn_Black {
                padding: 17px 25px;
                width: 100%;
            }
        }
    }
}

.login_parent {
    .leftSlider {
        background: $black;
        height: 100%;
        padding: 10px 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .logo img {
            margin-top: 15px;
        }
        .contentSlide {
            gap: 60px;
            margin-top: 60px;
            .whyChoose {
                font-weight: 900;
                font-size: 50px;
                line-height: 64px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
            .cocktailVector {
                img {width: 365px;}
            }
            .bottomContent {
                span {
                    font-weight: 200;
                    font-size: 30px;
                    line-height: 45px;
                    text-align: center;
                    color: #FFFFFF;
                }
                h6 {
                    font-family: 'Metropolis';
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 45px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
                p {
                    font-family: 'Metropolis';
                    font-weight: 200;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: center;
                    color: #FFFFFF;
                    letter-spacing: 1px;
                    line-height: 22px;
                }
                h4 {
                    font-family: 'Metropolis';
                    font-weight: 400;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 24px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
        .defaultCarousel {
            .carousel-indicators {
                bottom: -33px; 
                margin-bottom: 0;
                [data-bs-target] {
                    background-clip: unset;
                }
                button[data-bs-target] {
                    width: 6px !important;
                    border-radius: 10px;
                    height: 6px !important;
                    border: 0 !important;
                }
                button.active {
                    width: 30px !important;
                    height: 6px !important;
                }
            }
            
            .carousel-control-prev, .carousel-control-next {
                top: unset;
                bottom: unset;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                background-color: #fff;
                opacity: unset;
                .carousel-control-prev-icon, .carousel-control-next-icon {
                    filter: invert(100%);
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
    .rightForm {
        background-color: $white;
        height: 100%;
        padding: 50px 0;
        h4.loginTitle {
            @include font(30px, $black, 600);
            line-height: 40px;
            text-align: center;
        }
        p.formGuide {

            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #424242;
            margin-bottom: 0;
        }
        .signInOptions {
            gap: 15px;
            .google, .facebook {
                gap: 9px;
                border: 1px solid #A3A3A3;
                width: 260px;
                border-radius: 50px;
                background: transparent;
                padding: 12px 25px;
                img {
                    width: 20px;
                }
                span {


                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
        .partition {
            gap: 30px;
            .line {
                background: #D9D9D9;
                height: 1px;
                width: 20%;
            }
            .loginWithEmail {


                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.02em;
                color: #3C3C3C;
            }
        }
        .btn_Black {
            padding: 17px 25px;
            width: 100%;
        }
        .registerNow {
            .clrBlack_ {
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.02em;
                @include font(16px, $black, 500);
            }
            .clrOrange_ {


                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.linkBlk {
    @include font(15px, $black, 600);
    line-height: 24px;
    letter-spacing: 0.02em;
    &:hover {
        text-decoration: underline;
        @include font(15px, $black, 600);
    }
}
.membershipForm {
    .defLabel {
        padding: 0 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #000000;
    }
    .grayBG {
        background-color: #F2F2F2;
        padding: 15px;
        
    }
    .customRadioBtn .radioInput label {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #000000;
        padding-left: 40px;
        &::before {
            left: 0;
        }
        &::after {
            left: 5px;
        }
    }
    .font20Bold {
        font-family: 'Metropolis';
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #000000;
    }
    .font12BLk {
        font-family: 'Metropolis';
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.02em;
        color: #000000;
    }
}