// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import 'variable';
@import 'mixin';

body {
    font-family: 'Metropolis';
    font-style: normal;
    letter-spacing: 1px;
    background: url(../images/logoSymbol.png);
    background-blend-mode: multiply;
    background-repeat: repeat-y;
    background-size: 100%;
    overflow-x: hidden;
}
a{text-decoration: none;}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    // -webkit-appearance:none;
    box-shadow: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.navbarWebsite {
    background-color: $black;
    height: 96px;
    .logoAbs {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: table;
        padding: 0;
    }
    .navbar-brand img {
        width: auto;
    }
    .navbar-nav {
        align-items: center;
        .nav-link {
            @include font(13px, $white, bold);
            padding-right: 0.8rem;
            padding-left: 0.8rem;
            text-transform: uppercase;
            &:hover {
                color: $goldenNew;
            }
        }
        
    }
    .dropdown-menu {
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        border: 0;
        background-color: $black;
        .dropdown-item {
            padding: 0.5rem 1rem;
            @include font(13px, #898989, bold);
            text-transform: uppercase;
            &:hover {
                background-color: $black;
                color: $goldenNew;
            }   
        }
        .dropdown-item.active {
            background: none;
        }
    }
}
h2 {
    margin: 40px 0 !important;
}
.fontWhite34 {
    @include font(34px, $white, 200);
    font-family: 'Metropolis Extra';
    margin: 40px 0;
}
.fontBlack34 {
    @include font(34px, $black, 200);
    font-family: 'Metropolis Extra';
}
.font18Black {
    @include font(18px, $black, normal);
}
.font18White {
    @include font(18px, $white, normal);
}
.fontBlack34Bold {
    @include font(34px, $black, bold);
}
.font13Black {
    @include font(13px, $black, normal);
}
.link13Black {
    @include font(13px, $black, normal);
    text-decoration: underline;
}

.middlePart {
    padding-top: 96px;
}
.heroBanner {
    background-image: url(../images/bannerImg-min.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // min-height: 100vh;
    animation: shrink 5s ease;
    .bgBlkTransparent {
        background-color: rgba(0, 0, 0, .85);
        width: 100%;
        // min-height: 100vh;
    }
    .logoSymbol {
        background-image: url(../images/logoSymbol.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        width: 100%;
        min-height: 100vh;
        animation: shrinkSymbol 4s ease;
        animation-delay: 5s;
        // padding-top: 90px;
    }
    .customWidth {
        width: 80%;
        margin: 0 auto;
    }
    .logoAnimation {
        // width: 100%;
        // width: 375px;
        margin: 0 auto;
    }
    p {
        @include font(40px, $white, 200);
        font-family: 'Metropolis Extra';
    }
}
@keyframes shrink {
    0% {
      background-size: 105% 105%;
    }
    100% {
      background-size: 100% 100%;
    }
}
@keyframes shrinkSymbol {
    0% {
        opacity: 0;
        background-size: 150%;
    }
    20% {
        opacity: .5;
        background-size: 120%;
    }
    100% {
        opacity: 1;
        background-size: 100%;
    }
}

.secondBanner {
    background-image: url(../images/banner-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 90px);
}
.siteThemeBtn {
    background-color: $primary;
    border-radius: 0;
    border: 0;
    padding: 10px 20px;
    @include font(22px, $black, bold);
    text-transform: uppercase;
    &:hover,
    &:focus {
        background-color: $primary;
        border: 0;
    }
}
.siteBlackBtn {
    background-color: $black;
    border-radius: 0;
    border: 0;
    padding: 22px;
    @include font(22px, $white, bold);
    text-transform: uppercase;
    &:hover,
    &:focus {
        background-color: $black;
        border: 0;
        color: $white;
    }
}
.content {
    width: 100%;
    // padding: 75px 60px;
}
.societyAndEvent {
    width: 100%;
    // min-height: calc(100vh - 90px);
    display: flex;
    overflow: hidden;
    .societyPart {
        background-color: rgba($white, .5);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        position: relative;
        right: 0;
        z-index: 2;
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            background-color: rgba($white, .5);
            width: 100%;
            top: 0;
            right: 100%;
        }
        &::after {
            position: absolute;
            content: "";
            height: 100%;
            background-color: rgba($white, .5);
            width: 100%;
            top: 0;
            z-index: -1;
            left: 100%;
        }
    }
    .eventPart {
        background-color: rgba($black, .9);
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        left: 0;
        z-index: 2;
        // bottom: 0;
        &::after {
            position: absolute;
            content: "";
            height: 100%;
            background-color: rgba($black, .9);
            width: 100%;
            top: 0;
            left: 100%;
        }
    }
}
.vanuePart {
    width: 100%;
    background: rgba(#E2614E, .9);
    .vanueImg {
        // position: relative;
        // left: 0;
        // z-index: 2;
        // height: 100%;
        // &::before {
        //     position: absolute;
        //     content: "";
        //     height: 100%;
        //     width: 150%;
        //     top: 0;
        //     left: -50%;
        //     background-image: url(../images/venuesImg-min.png);
        //     background-position: center bottom;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        // }
        // img {
        //     width: 100%;
        // }
        background-image: url(../images/PLATFORM.jpg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
    }
    .vanueDetails {
        // background-color: rgba($primary, .9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}
.platformPart {
    width: 100%;
    // min-height: calc(100vh - 90px);
    background-color: rgba($goldenNew, .9);
    .leftPart {
    }
    .rightPart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}
.font13WhiteBold {
    @include font(13px, $white, bold);
}
.link13Golden {
    @include font(13px, $goldenNew, bold);
    &:hover {
        color: $goldenNew;
        text-decoration: underline;
    }
}
.footerLink {
    @include font(13px, $white, bold);
    &:hover {
        color: $white;
        text-decoration: underline;
    }
}
.borderTopWhite {
    border-top: solid 2px $white;
}
// Holborn page
.carouserCustom {
    .carousel-indicators [data-bs-target] {
        opacity: 1;
        width: 20px;
        height: 20px;
        border: 0;
        border-radius: 100%;
        background-color: $white;
    }
    .carousel-indicators .active {
        background-color: $primary;
    }
}
.holbornContent {
    background-color: $primary;
    padding: 60px 0;
    h1.font45Blk {
        @include font(45px, $black, bold);
    }
    p.font40Blk {
        @include font(40px, $black, 200);
        font-family: 'Metropolis Extra';
    }
}
.loungesPart {
    width: 100%;
    background: $white;
}
.meetingPart {
    width: 100%;
    background: $white;
}
.restaurantPart {
    width: 100%;
    background: $white;
}
.lastBG {
    background-color: rgba($primary, .9);
    height: 280px;
}
.loungesImg {
    background-image: url(../images/loungesImg.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 635px;
}
.meetingRoomImg {
    background-image: url(../images/meetingRoomImg.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 635px;
}
.restaurantImg {
    background-image: url(../images/restaurantImg.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 635px;
}

// Enquire page
.enquirePart {
    background-color: $white;
    .enquireImg {
        background-image: url(../images/enquireImg.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        height: 1240px;
    }
    .enquireForm {
        .form-label {
            @include font(13px, $black, normal);
            margin: 0;
        }
        .fieldColored {
            background-color: rgba(#CA9E67, .25);
            border-radius: 0;
            border: 0;
            padding: 10px;
            @include font(13px, $black, normal);
            &:focus {
                background-color: rgba(#CA9E67, .25);
                @include font(13px, $black, normal);
            }
            &:-webkit-autofill {
                box-shadow:0 0 0 0px #CA9E67 inset !important;
            }
        }
        .form-check-label {
            line-height: 22px;
        }
    }
    .checkboxCustom label {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out;
    
    }
    .checkboxCustom label .icon {
        width: 50px;
        height: 50px;
        border: solid 1px $black;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkboxCustom label p {
        line-height: 20px;
    }
    
    .checkboxCustom input[type=checkbox] {
        display: none !important;
    }
    
    .checkboxCustom input:checked+label .icon {
        transition: all 0.5s ease-in-out;
        background-color: $goldenNew;
      
    }
    .checkboxCustom label:hover {
        cursor: pointer;
    }
}
// Food And Beverage page
.FoodAndBeveragePart {
    background-color: $black;
    padding-top: 60px;
    .FoodAndBeverageBG {
        background-image: url(../images/logoSymbol.png);
        background-repeat: repeat-y;
        background-position: center -200px;
        background-size: 100%;
        background-color: $white;
        .bgWhite {
            background-color: rgba($white, .5);
        }
        .menuAbs {
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
}
.modalCustom {
    .modal-content {
        background-color: $black;
        border-radius: 0;
        padding: 20px;
        .modalPadding {
            background-color: $white;
            background-image: url(../images/logoSymbol.png);
            background-repeat: repeat-y;
            background-position: center -200px;
            background-size: 100%;
            .modal-header {
                &.redPattern {
                    background-color: rgba($primary, .8);
                }
                &.goldenPattern {
                    background-color: rgba(#E4CFB2, .8);
                    .modal-title {
                        @include font(18px, $black, 500);
                    }
                }
                border-radius: 0;
                .modal-title {
                    margin: 0 auto;
                    text-align: center;
                    @include font(18px, $white, 500);
                }
            }
            .modal-body {
                background-color: rgba($white, .5);
                .menuMain {
                    .menuPart {
                        text-align: center;
                        .font15Blk {
                            @include font(15px, $black, bold);
                        }
                        .font15Golden {
                            @include font(15px, $goldenNew, bold);
                        }
                        p {
                            @include font(15px, $black, 400);
                            line-height: 15px;
                        }
                    }
                }
            }
            .modal-footer {
                background-color: $black;
                border-radius: 0;
            }
        }
    }
}



@media (min-width: 320px) {
    .heroBanner .logoAnimation svg {
      width: calc(15rem + ((1vw - 3.2px) * 8.4375));
      min-height: 0vw;
    }
    .heroBanner p {
        font-size: calc(1.375rem + ((1vw - 3.2px) * 1.125));
        min-height: 0vw;
    }
    .siteThemeBtn,
    .siteBlackBtn {
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.5));
        min-height: 0vw;
    }
    .content {
        // padding-top: calc(2.5rem + ((1vw - 3.2px) * 2.1875));
        // padding-bottom: calc(2.5rem + ((1vw - 3.2px) * 2.1875));
        // padding-left: calc(1.625rem + ((1vw - 3.2px) * 3.125));
        // padding-right: calc(1.625rem + ((1vw - 3.2px) * 3.125));
        // min-height: 0vw;
    }
    .fontBlack34,
    .fontWhite34 {
        font-size: calc(1.75rem + ((1vw - 3.2px) * 0.375));
        min-height: 0vw;
    }
    .font18Black,
    .font18White {
        font-size: calc(0.9375rem + ((1vw - 3.2px) * 0.1875));
        min-height: 0vw;
    }
    .holbornContent h1.font45Blk {
        font-size: calc(2.1875rem + ((1vw - 3.2px) * 0.625));
        min-height: 0vw;
    }
    .holbornContent p.font40Blk {
        font-size: calc(1.75rem + ((1vw - 3.2px) * 0.75));
        min-height: 0vw;
    }
}






@media (max-width: 1479px) {
.px0 {
    padding-left: 0;
    padding-right: 0;
}
}
@media (max-width: 992px) {
    .loungesImg,
    .meetingRoomImg,
    .restaurantImg {
        height: 510px;
    }
    .navbarWebsite .navbar-toggler {
        background-color: $white;
        margin-left: auto;
    }
    .navbarWebsite .navbar-nav {
        background-color: $black;
        top: 100%;
        position: absolute;
        left: 0;
        min-height: 100vh;
        .nav-item {
            width: 100%;
        }
    }
    .mobileNavFull {
        width: 100%;
    }
    .nav-link,
    .navbarWebsite .dropdown-menu .dropdown-item {
        font-size: 14px !important;
        width: 100%;
    }
    .navbarWebsite .logoAbs {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        margin: unset;
    }
    .menu {
        background-color: transparent !important;
        display: flex;
        flex-direction: column;
        height: 36px;
        justify-content: space-between;
    }
    .menu span {
        width: 36px;
        height: 3px;
        background: #fff;
        transition: 0.3s ease;
        transform-origin: left;
    }
    .eventPart::before {
        position: absolute;
        content: "";
        height: 100%;
        background-color: rgba($black, .9);
        width: 100%;
        top: 0;
        right: 100%;
    }
}
@media (max-width: 767px) {
    .loungesImg,
    .meetingRoomImg,
    .restaurantImg {
        height: 360px;
    }
    .vanuePart .vanueImg {
        height: 360px;
    }
    .heroBanner .customWidth {
        width: 100%;
    }
    .partIcon {
        text-align: center;
    }
    .fontBlack34, .fontWhite34 {
        text-align: center;
    }
    .secondBanner {
        min-height: 583px;
        margin: 0 -15px;
        background-image: url(../images/banner-1.jpg);
    }
    // .societyAndEvent .eventPart,
    // .societyAndEvent .societyPart,
    // .vanuePart .vanueDetails {
    //     margin: 0 -15px;
    // }
    // .vanuePart .vanueImg::before {
    //     height: 338px;
    //     left: 0;
    //     width: 100%;
    //     display: none;
    // }
    // .vanuePart .vanueImg {
    //     background-image: url(../images/venuesImg-min.png);
    //     background-position: center bottom;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     height: 630px;
    //     left: 0;
    // }
    .footerSocial {
        margin-bottom: 30px;
    }
    .societyAndEvent .societyPart::after {
        display: none;
    }
    .carouserCustom {
        .slide1 {
            background-image: url(../images/slide1.png);
            background-repeat: no-repeat;
            background-position: center top;
            height: 300px;
            background-size: cover;
        }
        .slide2 {
            background-image: url(../images/slide2.png);
            background-repeat: no-repeat;
            background-position: center top;
            height: 300px;
            background-size: cover;
        }
        .slide3 {
            background-image: url(../images/slide3.png);
            background-repeat: no-repeat;
            background-position: center top;
            height: 300px;
            background-size: cover;
        }
    }

}
@media (min-width: 1400px) {
    
}
@media (min-width: 1920px) {
   
}
