// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import 'variable';
@import 'mixin';

body {
    font-family: 'Metropolis' !important;
    background-color: $black;
    // background: linear-gradient(90deg, rgba(255,117,64,1) 0%, rgba(161,196,222,1) 100%);
}
* {
    list-style-type: none !important;
    box-sizing: border-box !important;
}

button, a {
    cursor: pointer;
}

button {
    border: none;
}

.custBody {
    // background: url(../images/logoSymbol.png);
    // background-color: red;
    // background-blend-mode: multiply;
    // background-repeat: repeat-y;
    // background-size: 100%;
}
// ---- custom Radio button ----
.customRadioBtn {
    .radioInput {
        [type="radio"] {
            display: none;
        }
        label {
            position: relative;
            padding-left: 10px;
            cursor: pointer;
            display: inline-block;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: $black;
        }
        label:before {
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            width: 28px;
            height: 28px;
            border: 1px solid $black;
            border-radius: 100%;
            background: $white;
        }
        label:after {
            content: '';
            width: 18px;
            height: 18px;
            background: $black;
            position: absolute;
            top: 4px;
            left: -19px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        [type="radio"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}
// ----------

.fWeight-200 {
    font-weight: 200;
}
.fWeight-300 {
    font-weight: 300;
}
.fWeight-400 {
    font-weight: 400;
}
.fWeight-500 {
    font-weight: 500;
}
.fWeight-600 {
    font-weight: 600;
}
.fWeight-700 {
    font-weight: 700;
}
.fSize-8 {
    font-size: 8px;
}

.fSize-10 {
    font-size: 10px;
}
.fSize-12 {
    font-size: 12px;
}
.fSize-13 {
    font-size: 13px;
}
.fSize-14 {
    font-size: 14px;
}
.fSize-16 {
    font-size: 16px;
}
.fSize-18 {
    font-size: 18px;
}
.fSize-20 {
    font-size: 20px;
}
.fSize-21 {
    font-size: 21px;
}
.fSize-22 {
    font-size: 22px;
}
.fSize-24 {
    font-size: 24px;
}
.fSize-26 {
    font-size: 26px;
}
.fSize-28 {
    font-size: 28px;
}
.fSize-30 {
    font-size: 30px;
}

.bgLightBlue {
    background: #F3F9FD;
}

.page_main {
    // max-width: 1600px;
    // margin: 0 auto;
}

.divBg_white {
    background-color: $white;
    padding: 10px;
    box-shadow: 2px 1px 19px #00000052;
}

    .btn_Orange {
        background-color: $primary;
        @include font(16px, $white, 500);
        border-radius: 0;
        border: 1px solid $primary;
        padding: 10px 15px;
        height: fit-content;
        width: fit-content;
        line-height: unset;
        min-width: 100px;
        &:hover, &.active, &:focus {
            background-color: rgba($primary, .8);
            color: $white;
            border: 1px solid $primary;
        }
        &.sm {
            padding: 5px 15px;
        }
        &.lm{
            padding: 8px 20px;
            font-size: 22px;
            font-weight: 700;
        }
    }

    .btn_Black2 {
        background-color: #000;
        @include font(16px, $white, 500);
        border-radius: 0;
        border: 1px solid #000;
        padding: 10px 15px;
        height: fit-content;
        width: fit-content;
        line-height: unset;
        min-width: 100px;
        &:hover, &.active, &:focus {
            background-color: rgba(#000, .8);
            color: $white;
            border: 1px solid #000;
        }
        &.sm {
            padding: 5px 15px;
        }
        &.lm{
            padding: 8px 20px;
            font-size: 22px;
            font-weight: 700;
        }
    }
    
    .btn_White {
        background-color: $white;
        @include font(16px, $black, 500);
        border-radius: 0;
        border: 1px solid $black;
        padding: 10px 15px;
        height: fit-content;
        width: fit-content;
        &:hover, &.active, &:focus {
            background-color: $white;
            color: $black;
            border: 1px solid $black;
        }
        &.sm {
            padding: 5px 15px;
        }
        &.lm{
            padding: 8px 20px;
            font-size: 22px;
            font-weight: 700;
        }
    }
    .btn_black {
        background-color: $black;
        @include font(16px, $white, 500);
        border-radius: 0;
        border: 1px solid $black;
        padding: 10px 15px;
        height: fit-content;
        width: fit-content;
        &:hover, &.active, &:focus {
            background-color: $black;
            color: $white;
            border: 1px solid $black;
        }
        &.sm {
            padding: 5px 15px;
        }
        &.lm{
            padding: 8px 20px;
            font-size: 22px;
            font-weight: 700;
        }
    }
@media (max-width: 767px){
    .btn_Orange {
        &.lm{
            font-size: 20px;
        }
    }
    .btn_White {
        &.lm{
            font-size: 20px;
        }
    }
    .btn_black {
       &.lm{
            font-size: 20px;   
        }
    }
}

// ====== tab body section =====
.tabSection {
    .nav-tabs {
        gap: 2px;
        border: none;
        margin-bottom: 0 !important;
        .nav-item {
            .nav-link {
                background-color: #F0F0F0;
                color: #000;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                &.active, &:hover {
                    background-color: $black;
                    color: $white;
                    border: none;
                }
            }
        }
    }
}

// // ====== custom pagination =====
// .customPagination {
//     ul.pagination {
//         li {
//             .page-link {
//                 font-weight: 600;
//                 font-size: 20px;
//                 line-height: 30px;
//                 align-items: center;
//                 color: $white;
//             }
//         }
//         li.active {
//             .page-link {
//                     background-color: $black;
//             }
//         } 
//     }
// }

// ========
.claimModal {
    .modal-content {
        border-radius: 0px;
        .modal-header {
            .modal-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: $black;
            }
            .btn-close {
                background-color: #EBEBEB;
                color: #000;
                opacity: 1;
                border-radius: 50%;
                padding: 9px;
                height: 30px;
                width: 30px;
                // position: absolute;
                // top: 15px;
                // right: -13px;
                background-size: 10px;
                margin-right: 25px
            }
        }
        .claimForm {
            .modal-body {
                .defLabel_ {
                    font-size: 16px;
                    left: 16px;
                }
                .defInput_ {
                    height: 50px;
                    font-size: 14px;
                    padding-left: 23px;
                    &::placeholder {
                        font-size: 12px;
                    }
                }
                .defTextarea_ {
                    height: unset;
                    border-radius: 20px;
                    padding-top: 15px;
                }
                .defFileUpload_ {
                    height: 93px;
                    border-radius: 15px;
                    padding-top: 17px;
                    padding-left: 0;
                    cursor: pointer;
                    .inpIcon {
                        svg {
                            font-size: 35px;
                            color: #A3A3A3;
                        }
                        p {
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                            color: $black;
                        }
                    }
                }
                .customRadioBtn {
                    .form-check-label {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        padding-left: 15px;
                    }
                }
                .investorContent {
                    border-radius: 15px;
                    .title {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: $black;
                    }
                    .content {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: $black;
                    }
                }
            }
        }
        .modal-footer {
            .btn_White {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #000;
                padding: 10px 30px;
                &.lm {
                    padding: 8px 20px;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: unset;
                }
            }
            .btn_Black {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 10px 50px;
            }
        }
    }
}
// ==============

.dashboard {
    .welcomeSection {
        .welcomeTitle {
            font-weight: 600;
            font-size: 25px;
            line-height: 20px;
            color: $black;
        }
        span {
            font-weight: 300;
            font-size: 17px;
            line-height: 15px;
            color: #737373;
        }
        .verifyNow {
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            padding: 10px 18px;
            width: 220px;
        }
    }
    .sectionItem {
        .heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $black;
            margin-bottom: 0;
        }
        .topRight {
            .title {
                font-family: 'Metropolis Extra';
                font-weight: 200;
                font-size: 14px;
                line-height: 15px;
                color: $black;
            }
            .total {
                font-weight: 600;
                font-size: 14px;
                line-height: 30px;
                color: $black;
            }
        }
        .roi {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #489F36;
        }
        .boxParent {
            .boxItem {
                border: 1px solid #A3A3A3;
                border-radius: 10px;
                height: 100%;
                .title {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 18px;
                    color: #737373;
                }
                .content {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: $black;
                }
            }
            .boxItemGray {
                background-color: $GrayNew;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .title {
                    font-family: 'Metropolis Extra';
                    font-weight: 200;
                    font-size: 13px;
                    // line-height: 16px;
                    color: $black;
                }
                .bigtitle {
                    font-family: 'Metropolis Extra';
                    font-weight: 400;
                    font-size: 20px;
                    // line-height: 16px;
                    color: $black;
                }
                .content {
                    font-weight: 600;
                    font-size: 13px;
                    // line-height: 20px;
                    color: $black;
                }
                .bigcontent {
                    font-weight: 700;
                    font-size: 30px;
                    // line-height: 20px;
                    color: $black;
                }
                .fontGreen {
                    font-weight: 600;
                    font-size: 13px;
                    // line-height: 20px;
                    color: $GreenNew;
                }
            }
            .boxItemGolden {
                background-color: $goldenNew;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .title {
                    font-family: 'Metropolis Extra';
                    font-weight: 200;
                    font-size: 13px;
                    // line-height: 16px;
                    color: $black;
                }
                .bigtitle {
                    font-family: 'Metropolis Extra';
                    font-weight: 400;
                    font-size: 20px;
                    // line-height: 16px;
                    color: $black;
                }
                .content {
                    font-weight: 600;
                    font-size: 13px;
                    // line-height: 20px;
                    color: $black;
                }
                .bigcontent {
                    font-weight: 700;
                    font-size: 30px;
                    // line-height: 20px;
                    color: $black;
                }
            }
        }
    }
    .assetPortfolio {
        // .viewMore {
        //     font-weight: 500;
        //     font-size: 12px;
        //     line-height: 15px;
        //     letter-spacing: 0.05em;
        //     color: $white;
        //     padding: 7px 15px;
        //     border: solid 1px $black;
        // }
        // table {
        //     thead {
        //         background: #F3F9FD;
        //         th {
        //             font-weight: 400;
        //             font-size: 12px;
        //             line-height: 20px;
        //             color: $black;
        //         }
        //     }
        //     tbody {
        //         td {
        //             font-weight: 500;
        //             font-size: 13px;
        //             line-height: 22px;
        //             color: $black;
        //             // img {
        //             //     height: 30px;
        //             // }
        //         }
        //     }
        // }
    }
    .myInvestment {
        // .viewMore {
        //     font-weight: 500;
        //     font-size: 12px;
        //     line-height: 15px;
        //     letter-spacing: 0.05em;
        //     color: $white;
        //     padding: 7px 15px;
        //     border: solid 1px $black;
        // }
        .mCoin {
            .mCoinImg {
                height: 30px;
            }
            .title {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #737373;
            }
            .content {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: $black;

            }
            .addFund {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 7px 15px;
            }
        }
        .claimList {
            .title {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $black;
            }
            .title2 {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                color: $black;
            }
            .claimBtn {
                font-weight: 500;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 4px 22px;
            }
            .rightAmt {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $black;
            }
        }
    }
    .hotProposals {
        .items {
            border: 1px solid #E7E6E6;
            border-radius: 10px;
            overflow: hidden;
            .first {
                .title span {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 25px;
                    color: $black;
                }
                .same span:first-child {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 21px;
                    text-align: center;
                    letter-spacing: 0.32px;
                    color: #737373;
                }
                .same span:last-child {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 21px;
                    text-align: center;
                    color: $black;
                }
                .moreDetail .btn_Black {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.05em;
                    color: $white;
                    padding: 4px 13px;
                }
            }
            .sec {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                color: $black;
            }
        }
    }
}
// ===========

.proposal {
    .heading {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: $black;
    }
    .availableLiq {
        .title {
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            color: #737373;
        }
        .content {
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            color: $black;
        }
    }
    .createProposal {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        padding: 10px 25px;
        color: $white;
    }
    .tabSection {
        .items {
            border: 1px solid #E7E6E6;
            border-radius: 10px;
            overflow: hidden;
            .first {
                .same span:first-child {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 24px;
                    color: #737373;
                    text-align: center;
                    margin-bottom: 12px;
                }
                .same span:last-child {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                    color: $black;
                }
                .moreDetail {
                    .btn_Orange, .btn_Black {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 15px;
                        letter-spacing: 0.05em;
                        color: $white;
                        padding: 7px;
                        width: 160px;
                    }
                }
            }
            .sec {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                color: $black;
            }
        }
    }
}

.createProposal {
    .mainHeading {
        .backArrow {
            color: $black;
            &:hover {color: $black;}
            svg {
                font-size: 30px;
            }
        }
        h6 {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: $black;
        }
    }
    .propTitleSection {
        .heading h5 {
            font-weight: 600;
            font-size: 32px;
            line-height: 50px;
            color: $black;
        }
        .createdByMain {
            .createdBy {
                .title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    color: #737373;
                }
                .content {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    color: $black;
                }
                .btn_Black {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: $white;
                    padding: 8px 30px;
                }
            }
            p {
                font-weight: 400;
                font-size: 13px;
                line-height: 21px;
                letter-spacing: 0.32px;
                color: #737373;
            }
        }
        .voteProgress {
            .approveDiv {
                margin: 20px 0;
                button {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    text-align: center;
                    color: $white;
                    background-color: #009444;
                    padding: 7px 10px;
                    width: 180px; 
                }
                span {
                    position: absolute;
                    left: 50%;
                    top: 35%;
                    transform: translate(-50%,-50%);
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: -0.24px;
                    color: #A3A3A3;

                }
                svg {
                    height: 242px;
                    path.CircularProgressbar-trail {
                        stroke: #F4F4F4;
                    }
                    path.CircularProgressbar-path {
                        stroke: #009444;
                    }
                    text.CircularProgressbar-text {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        letter-spacing: -0.24px;
                        fill: #1A1A1A;
                    }
                }
            }
            .rejectDiv {
                margin: 20px 0;
                button {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    text-align: center;
                    color: $white;
                    background-color: #FF0000;
                    padding: 7px 10px;
                    width: 180px;
                }
                span {
                    position: absolute;
                    left: 50%;
                    top: 35%;
                    transform: translate(-50%,-50%);
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: -0.24px;
                    color: #A3A3A3;
                }
                svg {
                    height: 242px;
                    path.CircularProgressbar-trail {
                        stroke: #F4F4F4;
                    }
                    path.CircularProgressbar-path {
                        stroke: #FF0000;
                    }
                    text.CircularProgressbar-text {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        letter-spacing: -0.24px;
                        fill: #1A1A1A;
                    }
                }
            }
        }
        .propType {
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #737373;
            }
            .content {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
        }
        .proposalVotingTable {
            th {
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                color: $black;
                padding: 10px 10px 10px 20px;
            }
            td {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                padding: 10px 10px 10px 20px;
            }
        }
    }
    .propDescSection {
        border-radius: 20px;
        .heading h5 {
            font-weight: 700;
            font-size: 27px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: $black;
        }
        .dataContent {
            .title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            .content {
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            ul {
                padding-left: 1.5rem;
                li {
                    list-style: disc !important;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: $black;
                }
            }
        }
    }

}

.myInvestmentPage {
    .tabSection {
        .myInvestTab {
            .mainHeading h4 {
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: $black;
            }
            .bgLightBlue {
                border-radius: 10px;
                .lineOne {
                    .item {
                        .coinImg {
                            height: 40px;
                        }
                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #737373;
                        }
                        .title2 {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 24px;
                            text-align: center;
                            color: $black;
                        }
                        .content {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: $black;

                        }
                        .claimBtn {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0.05em;

                        }
                        .addLiqudity {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 32px;
                            text-align: center;
                            padding: 8px 25px;
                        }
                    }
                }
            }
            .innerTabSection {
                .cryptoTable {
                    table {
                        th {
                            padding: 20px 15px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            color: $black;
                            &:first-child {
                                text-align: left;
                            }
                        }
                        td {
                            padding: 20px 15px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            color: #737373;
                            &:first-child {
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .tableSection {
                table {
                    th {
                        padding: 20px 15px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: $black;
                        &:first-child {
                            text-align: left;
                        }
                    }
                    td {
                        padding: 20px 15px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: #737373;
                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}


.updateProfilePage {
    .mainHeading h5 {
        font-weight: 500;
        font-size: 23px;
        line-height: 30px;
        color: $black;

    }
    .profileSection {
        .profileImage {
            
            .iconBg {
                background-color: $black;
                top: 62%;
                left: 74%;
                height: 35px;
                width: 35px; 
                svg {
                    color: $white;
                    font-size: 15px;
                }
            }
        }
        .profileContent {
            // gap: 2px;
            .memberName {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: $black;
            }
            .memberId {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            .memberBio {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            .addKycBtn {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: $white;
                padding: 8px 30px;
            }
        }
    }
    .personalDetail {
        .heading {
            h6 {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            .editBtn {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 7px 20px;
            }
        }
        .formArea {
            .defLabel_ {
                font-size: 14px;
            }
            .defInput_ {
                height: 50px;
                font-size: 14px;
                padding-left: 32px;
            }
            .form-select {
                overflow: hidden;
                // overflow: -moz-hidden-unscrollable;
                // background: url([IMAGE]) no-repeat right [BACKGROUND-COLOR];
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url(../images/icDDarrow.png);
                background-repeat: no-repeat;
                background-position: center right 20px;
                background-size: unset;
            }
            .defTextarea_ {
                height: 100px;
                border-radius: 0;
                padding-top: 15px;
            }
            .saveBtn {
                font-weight: 500;
                font-size: 15px;
                line-height: 32px;
                padding: 6px 50px;
                text-align: center;
                color: $white;

            }
        }
    }
    .kycDetail {
        .heading {
            h6 {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            .editBtn {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 7px 20px;
            }
        }
        .item {
            .title {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
            .detail {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #A3A3A3;
            }
            .viewBtn {
                top: 0;
                right: 0;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.02em;
                text-decoration-line: underline;
                color: $black;

            }
        }
    }
}

.membershipDetailPage {
    .headingMain {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: $black;
    }
    .bgLightBlue {
        border-radius: 20px;
        .details {
            .title {
                font-weight: 400;
                font-size: 19px;
                line-height: 30px;
                color: $black;
            }
            .content {
                font-weight: 600;
                font-size: 19px;
                line-height: 30px;
                color: $black;
            }
            .btnChange, .btnCancel {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                padding: 15px 35px;
            }
        }
    }
}

.faqPage {
    .headingMain {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: $black;
    }
}
.Customaccordion {
    .accordion {
        .accordion-item {
            border: unset;
           border-radius: 0%;
            background-color: $lightgray;
            margin-bottom: 10px;
            .accordion-header {
                .accordion-button {
                    background: unset;
                    box-shadow: unset;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    color: $black;
                    &::after{
                        content: '+';
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #000;
                        color: #000;
                        padding-top: 1px;
                        font-weight: 400;
                        height: 23px;
                        width: 23px;
                        border-radius: 50%;
                        background-image: none;
                        transform: none;
                    }
                    &:not(.collapsed)::after{
                        content: '-';
                    }
                }
            }
            .accordion-body {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $black;
                padding-top: 0;

            }
        }
    }
}

.clubPage {
    .card {background-color:$lightgray};
    .clubItems {
        margin-bottom: 20px;
        .card-img-top {
            border-radius: 0%;
        }
        .card-body {
            .card-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: $black;
            }
        
            .ranking {
                svg {
                    font-size: 24px;
                }
                svg.clrGray {color: #C8C7C5;}
                svg.clrYellow {color: #F1BD03;}
                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: $black;
                }
            }
            p.hashRank, p.timing {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
        }
    }
}

.clubDetailPage {
    .sliderSection {
        .sliderImgSection {
            // box-shadow: 1px 5px 13px #00000066;
            .carousel {
                .carousel-indicators {display: none;}
                // .carousel-inner .carousel-item img {
                //     // border-radius: 15px 0 0 15px;
                // }
                .carousel-control-prev, .carousel-control-next {
                    top: 50%;
                    width: 42px;
                    height: 42px;
                    // border-radius: 50%;
                    background-color: $black;
                    opacity: unset;
                    margin: 0 10px;
                    span {
                        // filter: invert(100%);
                        height: 20px;
                        width: 20px;
                    }
                }
            }
            .checkInPosition {
                bottom: 0;
                left: 0;
                background-color: rgba(0,0,0,0.5);
                width:100%;
                
                p {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 30px;
                    text-transform: uppercase;
                    color: $white;
                    // max-width: 500px;
                }
                // .btn_CheckIn {
                //     font-weight: 600;
                //     font-size: 14px;
                //     line-height: 20px;
                //     text-align: center;
                //     letter-spacing: 0.05em;
                //     color: $black;
                // }
            }
        }
        .contentSection {
            border-radius: 0% !important;
            .card {background-color:$lightgray};
            .card .card-body {
                .card-title {
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 35px;
                    color: $black;
                }
                .ranking {
                    svg {
                        font-size: 24px;
                    }
                    svg.clrGray {color: #C8C7C5;}
                    svg.clrYellow {color: #F1BD03;}
                    span {
                        font-weight: 400;
                        font-size: 14px;
                        // line-height: 20px;
                        color: $black;
                    }
                }
                p.hashRank, p.timing {
                    font-weight: 400;
                    font-size: 14px;
                    // line-height: 15px;
                    color: $black;
                }
                p.basicContent {
                    font-weight: 400;
                    font-size: 14px;
                    // line-height: 25px;
                    color: $black;
                }
            }
            .totalAttendeePosition {
                top: 0;
                right: 20px;
                background-color: $black;
                border-radius: 0 0 15px 15px;
                .title {
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 25px;
                    color: $white;
                }
                .content {
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 40px;
                    color: $white;
                }
            }
        }

    }
    .clubDetailItems {
        .cardItems {
            margin-bottom: 20px;
       
        .card-body {
            .card-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: $black;
            }
            .pricing {
                .newPrice {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }
                .oldPrice {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-decoration-line: line-through;
                    color: #A3A3A3;
                }
            }
          
            p.mCoinContent {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: $black;
            }
        }
        }
    }
    .eventsCardSection {
        background-color: $lightgray;
        .mainHeading h6 {
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            // text-align: center;
            color: $black;
        }
        .sliderHeight {
            height: 800px;
        }
        .carousel {
            height: 95%;
            .carousel-indicators {
                bottom: -40px;
                button {
                    border-radius: 50%;
                    border: none;
                    height: 12px;
                    width: 12px;
                }
            }
            .carousel-inner {
                height: 100%;
                .carousel-item {
                    height: 100%;
                    .carousel-caption {
                        height: 100%;
                        .mainTitle {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 32px;
                            color: $black;
                        }
                        .linkUpto {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;
                            color: $black;
                        }
                        .date {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 32px;
                            color: $black;
                        }
                        .para1 {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 32px;
                            color: $black;
                        }
                        .line {
                            width: 180px;
                            border: 1px solid $black;
                            opacity: 1;
                        }
                        .finale {
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 32px;
                            color: $black;
                        }
                        .para2 {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 20px;
                            text-align: center;
                            color: $black;
                        }
                    }
                }
            }
            .carousel-control-prev, .carousel-control-next {
                top: 100%;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                // background-color: $black;
                // opacity: unset;
                margin: 0 10px;
              
            }
        }
        .bgLightBlue {
            border-radius: 20px;
        }
    }
}
.gap80 {
    gap: 80px;
}
.gap50 {
    gap: 50px;
}
.labourPoolPage {
    .mainHeading {
        h5 {
            font-weight: 500;
            font-size: 25px;
            line-height: 39px;
            color: $black;
        }
        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #737373;
        }
    }
    
}
.tab-content{
    .topSec {
        .leftHeading {
            text-align: left;
            .title {
                font-family: Metropolis;
                font-weight: 500;
                font-size: 26px;
                line-height: 30px;
                color: $black;
            }
            .inpParent {
                border: 1px solid #A3A3A3;
                overflow: hidden;
                height: 50px;
                // width: 430px;
                input {
                    border: none;
                    outline: none;
                    height: 100%;
                    width: 100%;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: $black;
                    &::placeholder {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: #000000a8;
                    }
                }
                .btnSearch {
                    background: transparent;
                    svg {
                        font-size: 24px;
                    }
                }
            }
        }
        .rightBtn {
            text-align: right;
            .mCoinPara {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $black;
            }
            .submitBtn {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: $white;
                padding: 8px 30px;
            }
        }
    }
}

    .profileSection {
        .profileItem {
            border-radius: 15px;
            .profileName {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: $black;
            }
            .same {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
            }
            .profilePlace {
                border: 1px solid $black;
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: $black;
                padding: 0px 8px;
            }
            .profileDesc {
                .title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                }
                .desc {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: $black;
                }
            }
            .btnSame {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                padding: 8px 30px;
                width: 150px;
            }
        }
    }
    .submitProfileSection {
        form {
            .defInput_, .defTextarea_ {
                font-size: 14px;
                &::placeholder {
                    font-size: 14px;
                }
            }
            .defFileUpload_ {
                height: 100px;
                border-radius: 15px;
                padding-top: 17px;
                padding-left: 0;
                cursor: pointer;
                .inpIcon {
                    svg {
                        font-size: 40px;
                        color: #A3A3A3;
                    }
                }
            }
            .defTextarea_ {
                border-radius: 15px;
                height: unset;
                padding-top: 17px;
            }
            .btn_White, .btn_Black {
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
                padding: 8px 40px;
            }
            .btn_Black {
                padding: 9px 40px;
            }
        }
    }
    .profileDetail {
        .mainHeading {
            h6 {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: $black;
            }
            .btn_Black {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.05em;
                padding: 4px 22px;
            } 
        }
        .same {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: $black;
        }
        .profilePlace {
            border: 1px solid $black;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $black;
            padding: 0px 8px;
        }
        .para {
            max-width: 650px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #737373;
        }
        .otherDetails {
            .title {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #737373;
            }
            .content {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: $black;
            }
        }
        .btn_White, .btn_Orange {
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            padding: 8px 40px;
        }
        .btn_Orange {
            padding: 9px 40px;
        }
    }



.assetPortfolioPage {
    h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: $black;
    }
    .bgLightBlue {
        border-radius: 10px;
        .lineOne {
            .item {
                .coinImg {
                    height: 40px;
                }
                .title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #737373;
                }
                .title2 {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                    color: $black;
                }
                .content {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: $black;

                }
                .claimBtn {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.05em;

                }
                .addLiqudity {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    text-align: center;
                    padding: 8px 25px;
                }
            }
        }
    }
    .btnSM {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: $white;
        padding: 7px 15px;
    }
    .assetPortfolioTable {
        table {
            th {
                // width: 25%;
                padding: 20px 15px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: $black;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
            td {
                padding: 20px 15px;
                font-weight: 500;
                font-size: 13px;
                line-height: 22px;
                color: $black;
                text-align: center;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
.title {
    font-family: 'Metropolis Extra';
    font-weight: 200;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
}
.bgTransparent {
    background-color: transparent !important;
}
.marquee-container {
    min-height: 300px !important;
}
.marquee-container .marquee {
    width: 100%;
}
.font14Red {
    color: #FF0000;
    @include font(14px, #FF0000, 400);
}
marquee.move:hover {
    cursor: pointer;
}
@media (max-width: 1024px) {
    .login_parent .leftSlider .defaultCarousel .contentSlide .whyChoose {
        font-size: 30px;
    }
    .login_parent .leftSlider .defaultCarousel .contentSlide .bottomContent span,
    .login_parent .leftSlider .defaultCarousel .contentSlide .bottomContent h6 {
        font-size: 20px;
    }
    .login_parent .leftSlider .defaultCarousel .contentSlide .bottomContent p {
        font-size: 16px;
    }
    .login_parent .leftSlider .defaultCarousel .contentSlide {
        gap: 20px;
        margin-top: 20px;
    }
    .gap80 {
        gap: 40px;
    }
    .gap50 {
        gap: 30px;
    }
    .login_parent .rightForm .partition {
        gap: 10px;
    }
    .login_parent .rightForm {
        padding: 20px 0;
    }
    .clubDetailPage .sliderSection .contentSection .totalAttendeePosition .content {
        font-weight: 300;
        font-size: 13px;
        line-height: 25px;
    }
    .clubDetailPage .sliderSection .contentSection .totalAttendeePosition .title {
        display: inline-block !important;
        padding-right: 10px;
    }
    .clubDetailPage .sliderSection .contentSection .totalAttendeePosition {
        padding: 0px 13px !important;
    }
}
@media (max-width: 992px) {
    .gap80 {
        gap: 50px;
    }
    // .clubDetailPage .sliderSection .sliderImgSection .carousel .carousel-inner .carousel-item img {
    //     border-radius: 15px 15px 0 0;
    // }
    // .clubDetailPage .sliderSection .contentSection {
    //     border-radius: 0 0 15px 15px;
    // }
}
@media (max-width: 767px) {
    .dashboard {
        .sectionItem {
            .boxParent {
                gap: 10px;
            }
        }
    }
    .dashboard .myInvestment .mCoin .addFund {
        padding: 7px 10px;
    }
    .dashboard .myInvestment .claimList .claimBtn {
        padding: 4px 10px;
    }
    .myInvestTab .itemMob {
        display: flex;
        justify-content: space-between;
    }
    .tabSection .nav-tabs {
        width: 100%;
        overflow: scroll;
        flex-wrap: nowrap;
        overflow-y:hidden;
        // height: 40px;
    }
    .proposal .tabSection .items .same {
        width: 100%;
    }
    .proposal .tabSection .items .same span {
        width: 100%;
        text-align: left !important;
        display: block;
    }
    .proposal .tabSection .items .first .same span:first-child {
        margin: 0;
    }
    .assetPortfolioPage .bgLightBlue .itemMob {
        display: flex;
        justify-content: space-between;
    }
    .labourPoolPage .tabSection .topSec .rightBtn {
        margin-top: 20px;
    }
    .page_main h1 span {
        display: block;
        margin-left: 0 !important;
    }
}

/* CSS */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-btn {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-btn:hover {
    background-color: #f0f0f0;
  }
  
  .pagination-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .pagination-btn.active {
    background-color: $black;
    color: #fff;
    border-color: $black;
  }
  
  .voteProgress {
    .approveDiv {
        margin: 20px 0;
        button {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: $white;
            background-color: #009444;
            padding: 7px 10px;
            width: 180px; 
        }
        span {
            position: absolute;
            left: 50%;
            top: 35%;
            transform: translate(-50%,-50%);
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: -0.24px;
            color: #A3A3A3;

        }
        svg {
            height: 200px;
            path.CircularProgressbar-trail {
                stroke: #F4F4F4;
            }
            path.CircularProgressbar-path {
                stroke: #009444;
            }
            text.CircularProgressbar-text {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.24px;
                fill: #1A1A1A;
            }
        }
    }
    .rejectDiv {
        margin: 20px 0;
        button {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: $white;
            background-color: #FF0000;
            padding: 7px 10px;
            width: 180px;
        }
        span {
            position: absolute;
            left: 50%;
            top: 35%;
            transform: translate(-50%,-50%);
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: -0.24px;
            color: #A3A3A3;
        }
        svg {
            height: 200px;
            path.CircularProgressbar-trail {
                stroke: #F4F4F4;
            }
            path.CircularProgressbar-path {
                stroke: #FF0000;
            }
            text.CircularProgressbar-text {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.24px;
                fill: #1A1A1A;
            }
        }
    }
}

#progress-bar--container1, #progress-bar--container2 {
    display: flex;
    justify-content: start;
    align-items: start;

    svg {
        transform: rotate(-90deg);
      }
  }
  
  #progress--circle1, #progress--circle2 {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
  }
  
  #progress--text1 {
    font-family: var(--primary-font);
    fill: var(--primary-color);
    font-weight: 500;
    font-size: 5.5px;
    transform-origin: center;
    transform: rotate(90deg);
  }

  #progress--text2 {
    font-family: var(--primary-font);
    fill: var(--primary-color);
    font-weight: 750;
    font-size: 6px;
    transform-origin: center;
    transform: rotate(90deg);
  }
  
.notifications-container {
    height: 200px;
    overflow-y: scroll;
    min-width: 480px;
    @media (max-width: 468px) {
        min-width: 100%;
    }
}
.navbar-expand-xxl .navbar-nav .dropdown-menu {
    left: auto;
    right: 0;
}