@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.eot');
    src: url('../fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../fonts/Metropolis-Regular.woff') format('woff'),
        url('../fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLight.eot');
    src: url('../fonts/Metropolis-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraLight.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLight.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.eot');
    src: url('../fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../fonts/Metropolis-Bold.woff') format('woff'),
        url('../fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

