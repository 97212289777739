button {
    &:disabled {
        opacity: .65;
    }
}
.membershipDetailPage .bgLightBlue .details .btnCancel {
    line-height: unset;
    padding: 10px 15px;
    font-size: 16px;
    border: solid 1px #000;
}